import AboutSection from "../components/AboutSection";

const About = () => {
  return (
    <>
      <AboutSection />
    </>
  );
};

export default About;
