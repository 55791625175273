import IqviaSection from "../components/IqviaSection";

const Iqvia = () => {
  return (
    <>
      <IqviaSection />
    </>
  )
}

export default Iqvia