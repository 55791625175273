import PaeSection from "../components/PaeSection";


const Pae = () => {
  return (
    <>
      <PaeSection />
    </>
  )
}

export default Pae