import WorkSection from "../components/WorkSection";


const Work = () => {
  return (
    <>
      <WorkSection />
    </>
  )
}

export default Work